<template>
  <flipCards :cards_="cards"/>
</template>

<script>
import { defineComponent } from 'vue'
import flipCards from '@/pages/docs/globalComponents/flipCards'

const cards = [
    {
      front: 'Kainos (ar sąnaudų) ir kokybės santykio (K/SKS) kriterijus',
      back: '❌🙅 Ne, šis kriterijus nustatomas atsižvelgiant į pirkimo objekto atributus. Pavyzdžiui techninius pranašumus.',
      flipped: false,
    },
    {
      front: 'Sąnaudų kriterijus (SK)',
      back: '✅ Teisingai! Kriterijus nustatomas pagal GCS metodą.',
      flipped: false,
    },
    {
      front: 'Kainos kriterijus (KK)',
      back: '❌🙅 Ne, šis kriterijus nustatomas naudojant mažiausios kainos metodą.',
      flipped: false,
    },
];

export default defineComponent({
  components: {
    flipCards,
  },
  setup() {
    return {
      cards
    }
  },

  methods: {
  }
});
</script>

<style scoped>
</style>
